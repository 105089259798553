.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

@keyframes pulse-grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.dot-grow {
  animation: pulse-grow 1s infinite;
}

/* body{
  color: #121212 !important;
} */

.introjs-tooltip {
  background-color: #121212 !important; /* Background color */
  color: #fff !important; /* Text color */
  font-family: 'Roboto', sans-serif !important; /* Material font */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important; /* Material Design shadow */
}

.introjs-tooltip-header {
  background-color: #121212 !important; /* Header background color */
  color: #fff !important; /* Header text color */
}

.introjs-tooltiptext {
  color: #fff !important; /* Text color */
}

.introjs-helperNumberLayer {
  background-color: #121212 !important; /* Step number background */
  color: #fff !important; /* Step number color */
}

.introjs-arrow {
  border-top-color: #fff !important; /* Tooltip arrow color */
}


